import { updateTaxas } from '@/utils/services/instituto'
import useJwt from '@/auth/jwt/useJwt'
import { formatarParaNumber, formatarValor } from '@/libs/utils'
import * as mathjs from 'mathjs'

export async function atualizarTaxas(userData) {
  try {
    userData = await carregaIndice(userData)

    //recalcular
    userData = await calcularTaxas(userData)

    //salvar
    await salvarCalculo(userData)
  } catch (error) {
    console.error('Erro ao atualizar taxas:', error)
  }
}

async function carregaIndice(userData) {
  const tipoIndiceSelecionado = userData.Instituto.tipoIndiceSelecionado || 'IPCA'
  try {
    const response = await useJwt.pesquisar('cadastro/instituto/GetpesquisarIndices', { tipoIndice: tipoIndiceSelecionado })

    const taxaSemFormatar = formatarValor(response.data.taxaAcumulado).replace('R$ ', '')

    userData.Instituto.taxaIndiceInflacionario = taxaSemFormatar
    userData.Instituto.taxaIndiceInflacionarioMensal = response.data.taxa

    userData = await calculaJurosAnual(userData)
    return userData
  } catch (error) {
    console.error('Erro ao carregar índice:', error)
  }
}

async function calculaJurosAnual(userData) {
  let taxaEmprestimoMensal = formatarParaNumber(userData.Instituto.taxaEmprestimoMensal) / 100

  let formula = '((A + 1) ^ 12 - 1) * 100'
  let escopo = {
    A: taxaEmprestimoMensal,
  }

  userData.Instituto.taxaEmprestimoAnual = Number(mathjs.evaluate(formula, escopo)).toFixed(2)

  return userData
}

async function calcularTaxas(userData) {
  let taxaIndiceInflacionario = mathjs.bignumber(formatarParaNumber(userData.Instituto.taxaIndiceInflacionario) || 0)
  let jurosAtuarial = mathjs.bignumber(formatarParaNumber(userData.Instituto.jurosAtuarial) || 0)
  let taxaAdministrativa = mathjs.bignumber(formatarParaNumber(userData.Instituto.taxaAdministrativa) || 0)
  let taxaEmprestimoAnual = mathjs.bignumber(formatarParaNumber(userData.Instituto.taxaEmprestimoAnual) || 0)
  let taxaOscilacaoRiscos = mathjs.bignumber(formatarParaNumber(userData.Instituto.taxaOscilacaoRiscos) || 0)
  let taxaFundoGarantia = mathjs.bignumber(formatarParaNumber(userData.Instituto.taxaFundoGarantia) || 0)

  taxaEmprestimoAnual = formatarParaNumber(taxaEmprestimoAnual)
  taxaIndiceInflacionario = formatarParaNumber(taxaIndiceInflacionario)
  jurosAtuarial = formatarParaNumber(jurosAtuarial)
  taxaAdministrativa = formatarParaNumber(taxaAdministrativa)
  taxaOscilacaoRiscos = formatarParaNumber(taxaOscilacaoRiscos)
  taxaFundoGarantia = formatarParaNumber(taxaFundoGarantia)

  let calculoDaTaxaDeOportunidade = Number(
    mathjs
      .evaluate(`( jurosAoAno - ( A + B + C + D + E) )`, {
        jurosAoAno: taxaEmprestimoAnual,

        A: taxaAdministrativa,
        B: taxaOscilacaoRiscos,
        C: taxaFundoGarantia,
        D: jurosAtuarial,
        E: taxaIndiceInflacionario,
      })
      .toFixed(4),
  )
  calculoDaTaxaDeOportunidade = calculoDaTaxaDeOportunidade * 100 <= 0 ? 0 : calculoDaTaxaDeOportunidade
  userData.Instituto.taxaDeOportunidade = formatarValor(calculoDaTaxaDeOportunidade)

  return userData
}

async function salvarCalculo(userData) {
  const dados = {
    ...userData.Instituto,
    taxaAdministrativa: formatarParaNumber(userData.Instituto.taxaAdministrativa),
    taxaOscilacaoRiscos: formatarParaNumber(userData.Instituto.taxaOscilacaoRiscos),
    taxaEmprestimoAnual: formatarParaNumber(userData.Instituto.taxaEmprestimoAnual),
    taxaFundoGarantia: formatarParaNumber(userData.Instituto.taxaFundoGarantia),
    taxaIndiceInflacionario: formatarParaNumber(userData.Instituto.taxaIndiceInflacionario),
    taxaEmprestimoMensal: formatarParaNumber(userData.Instituto.taxaEmprestimoMensal),
    jurosAtuarial: formatarParaNumber(userData.Instituto.jurosAtuarial),
    taxaDeOportunidade: formatarParaNumber(userData.Instituto.taxaDeOportunidade),
    institutoId: userData.Instituto.id,
  }

  await updateTaxas(userData.Instituto.id, dados)
}
